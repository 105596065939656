





















import Vue from "vue";
import LineChart from "@/components/LineChart.vue";
import Component from "vue-class-component";
import { movingAvg, round } from "@/utils/stats";

interface RegionDayVaccines {
  index: number; // Codice identificativo del record.
  area: string; // Sigla della regione di consegna.
  data_somministrazione: string; // Data di somministrazione.
  totale: number; // Numero totale di dosi di vaccino somministrate per giorno e regione.
  fascia_anagrafica: string; // Identifica la fascia anagrafiche in cui appartengono i soggetti a cui è stato somministrato il vaccino.
  sesso_maschile: number; // Totale dei soggetti di sesso maschile a cui è stato somministrato il vaccino per giorno, regione e fascia d’età.
  sesso_femminile: number; // Totale dei soggetti di sesso femminile a cui è stato somministrato il vaccino per giorno, regione e fascia d’età.
  categoria_operatori_sanitari_sociosanitari: number; // Totale dei soggetti appartenenti alla categoria degli operatori sanitari e sociosanitari a cui è stato somministrato il vaccino per giorno, regione e fascia d'età.
  categoria_personale_non_sanitario: number; // Totale dei soggetti appartenenti alla categoria del personale non sanitario a cui è stato somministrato il vaccino per giorno, regione e fascia d'età.
  categoria_ospiti_rsa: number; // Totale dei soggetti ospiti della struttura residenziale a cui è stato somministrato il vaccino per giorno, regione e fascia d'età.
  categoria_over80: number; // Numero somministrazioni categoria di rischio prevalente over 80 per giorno, regione e fascia d'età.
  prima_dose: number; // Numero prime somministrazioni.
  seconda_dose: number; // Numero seconde somministrazioni.
  codice_NUTS1: string; // Classificazione europea delle unità territoriali NUTS: livello NUTS 1.
  codice_NUTS2: string; // Classificazione europea delle unità territoriali NUTS: livello NUTS 2.
  codice_regione_ISTAT: number; // Codice ISTAT della Regione.
  nome_area: string; // Denominazione standard dell'area (dove necessario denominazione bilingue).
}

interface RegionDayData {
  schema: unknown;
  data: RegionDayVaccines[];
}

interface RegionDayDeliveries {
  index: number; // Codice identificativo del record.
  area: string; // Sigla della regione di consegna.
  fornitore: string; // Nome completo del fornitore del vaccino.
  data_consegna: string; // Il giorno in cui è avvenuta la consegna.
  numero_dosi: number; // Il numero di dosi del vaccino consegnate in quel giorno per regione.
  codice_NUTS1: string; // Classificazione europea delle unità territoriali NUTS: livello NUTS 1.
  codice_NUTS2: string; // Classificazione europea delle unità territoriali NUTS: livello NUTS 2.
  codice_regione_ISTAT: number; // Codice ISTAT della Regione.
  nome_area: string; // Denominazione standard dell'area (dove necessario denominazione bilingue).
}

interface DeliveryData {
  schema: unknown;
  data: RegionDayDeliveries[];
}

interface Region {
  code: string;
  name: string;
}

@Component({
  components: {
    LineChart,
  },
})
export default class Vaccini extends Vue {
  private loaded = false;
  private chartdata = {};
  private regions: Region[] = [];
  private region = "";
  private rawVaccinesData: RegionDayVaccines[] = [];
  private rawDeliveriesData: RegionDayDeliveries[] = [];
  private movingAverage = true;

  async mounted(): Promise<void> {
    this.loaded = false;

    if (
      this.$store.state.regionsVaccineData.length &&
      this.$store.state.regionsDeliveriesData.length
    ) {
      this.init(
        this.$store.state.regionsVaccineData,
        this.$store.state.regionsDeliveriesData
      );
      return;
    }

    const repoSite =
      "https://raw.githubusercontent.com/italia/covid19-opendata-vaccini/master/dati";
    Promise.all([
      fetch(
        `${repoSite}/somministrazioni-vaccini-summary-latest.json`
      ).then((res) => res.json()),
      fetch(`${repoSite}/consegne-vaccini-latest.json`).then((res) =>
        res.json()
      ),
    ]).then(
      ([rawVaccinationsData, rawDeliveriesData]: [
        RegionDayData,
        DeliveryData
      ]) => {
        rawVaccinationsData.data.sort((datum1, datum2) => {
          const date1 = new Date(datum1.data_somministrazione);
          const date2 = new Date(datum2.data_somministrazione);

          return date1.getTime() - date2.getTime();
        });
        rawDeliveriesData.data.sort((datum1, datum2) => {
          const date1 = new Date(datum1.data_consegna);
          const date2 = new Date(datum2.data_consegna);

          return date1.getTime() - date2.getTime();
        });
        this.$store.state.regionsVaccineData = rawVaccinationsData.data;
        this.$store.state.regionsDeliveriesData = rawDeliveriesData.data;
        this.init(rawVaccinationsData.data, rawDeliveriesData.data);
      }
    );
  }

  private init(
    rawVaccinesData: RegionDayVaccines[],
    rawDeliveriesData: RegionDayDeliveries[]
  ): void {
    this.rawVaccinesData = rawVaccinesData;
    this.rawDeliveriesData = rawDeliveriesData;

    const inserted: string[] = [];

    const regions: Region[] = rawVaccinesData
      .reduce((carrier: Region[], stats) => {
        carrier.push({
          code: stats.area,
          name: stats.nome_area,
        });

        return carrier;
      }, [])
      .filter((region) => {
        if (inserted.indexOf(region.code) > -1) {
          return false;
        }

        inserted.push(region.code);

        return region.code;
      })
      .sort((reg1, reg2) => {
        if (reg1.code === "EMR") {
          return -10;
        }
        if (reg2.code === "EMR") {
          return 10;
        }
        return reg1.name.localeCompare(reg2.name);
      });

    this.regions = regions;
    this.region = regions[0].code;

    this.plot(this.region);
  }

  private plot(regionCode: string) {
    const labels: string[] = [];
    let totalDoses = 0;
    let totalDosesToPlot: number[] = [];
    let vaccinations: number[] = [];
    let deliveriesToPlot: number[] = [];
    let deliveriesData: Record<string, number> = {};

    const deliveriesDataToPlot = this.rawDeliveriesData.filter(
      (stats) => stats.area === regionCode
    );

    deliveriesDataToPlot.forEach((dayStats) => {
      const x = new Date(
        dayStats.data_consegna.substr(0, 10)
      ).toLocaleDateString();
      if (!deliveriesData[x]) {
        deliveriesData[x] = 0;
      }
      deliveriesData[x] += dayStats.numero_dosi;
    });

    const vaccinesDataToPlot = this.rawVaccinesData.filter(
      (stats) => stats.area === regionCode
    );

    vaccinesDataToPlot.forEach((dayStats) => {
      const x = new Date(
        dayStats.data_somministrazione.substr(0, 10)
      ).toLocaleDateString();
      labels.push(x);
      vaccinations.push(dayStats.totale);
      deliveriesToPlot.push(deliveriesData[x] || 0);
      totalDoses += (deliveriesData[x] || 0) - dayStats.totale;
      totalDosesToPlot.push(totalDoses);
    });

    if (this.movingAverage) {
      vaccinations = round(movingAvg(vaccinations, 7));
      deliveriesToPlot = round(movingAvg(deliveriesToPlot, 7));
      totalDosesToPlot = round(movingAvg(totalDosesToPlot, 7));
    }

    this.chartdata = {
      labels,
      datasets: [
        {
          label: "Vaccinazioni",
          backgroundColor: "blue",
          borderColor: "blue",
          fill: false,
          data: vaccinations,
        },
        {
          label: "Consegne",
          backgroundColor: "navy",
          borderColor: "navy",
          fill: false,
          data: deliveriesToPlot,
          hidden: true,
        },
        {
          label: "Rimanenze",
          backgroundColor: "red",
          borderColor: "red",
          fill: false,
          data: totalDosesToPlot,
          hidden: true,
        },
      ],
    };

    this.loaded = true;
  }

  private rePlot() {
    this.plot(this.region);
  }
}
